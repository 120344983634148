<template>
  <div>
    <b-modal
      v-model="isModalShow"
      title="หลักฐานการชำระเงิน"
      scrollable
      @hide="setShowModalPayment(false, false)"
    >
      <div style="max-height:600px" v-if="orderTransferObj">
        <b-row>
          <b-col class="mb-2">
            <span class=""
              >หลักฐานการโอนเงิน
              <small
                v-if="
                  isValidate &&
                    orderTransferObj.imageList.length == 0 &&
                    imageList.length === 0 &&
                    selectedApprove
                "
                style="color:red;"
                >กรุณาเลือกรูปภาพ</small
              ></span
            >
          </b-col>
        </b-row>
        <div
          cols="6"
          v-for="(item, index) in orderTransferObj.imageList"
          :key="index + 'dbfileimage'"
        >
          <b-row class="mb-2">
            <b-col>
              <img
                :src="item.imageUrl"
                alt=""
                style="width:100%;height:100%;"
              />
            </b-col>
            <b-col cols="6">
              <div>
                <span
                  >ชื่อไฟล์:
                  {{
                    item.imageUrl?.substring(item.imageUrl.lastIndexOf("/") + 1)
                  }}
                </span>
                <font-awesome-icon
                  v-if="orderStatus === 3 && permission"
                  class="text-color-black"
                  icon="times"
                  @click="onEditImageFromDB(false, index)"
                />
              </div>
              <div class="text-color-secondary text-small">
                <u
                  v-if="orderStatus === 3 && permission"
                  @click="onEditImageFromDB(true, index)"
                  >แก้ไขหลักฐานการชำระเงิน</u
                >
              </div>
            </b-col>
          </b-row>
        </div>
        <div v-for="(item, index) in imageList" :key="index + 'fileimage'">
          <b-row class="mt-2">
            <b-col cols="6">
              <img :src="item" alt="" style="width:100%;height:100%;" />
            </b-col>
            <b-col>
              <div>
                <span
                  >ชื่อไฟล์: {{ fileNameLists[index] }}
                  <font-awesome-icon
                    class="text-color-black"
                    icon="times"
                    @click="onDeleteImageItem(index)"
                /></span>
              </div>
              <div class="text-color-secondary text-small">
                <u>แก้ไขหลักฐานการชำระเงิน</u>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="my-2" v-if="orderStatus == 3 && permission">
          <b-col cols="6">
            <div
              class="mx-1 bg-color-secondary d-table-cell align-middle text-center pointer"
              style="width:150px;height:150px;color:black;"
              @click="clickImportImage(-1)"
            >
              <div>
                <font-awesome-icon icon="plus-circle" size="lg" />
                <div class="text-small">กดเพื่ออัพโหลดรูปภาพ</div>
              </div>
            </div>
            <input
              ref="inputImageFile"
              style="display:none"
              type="file"
              multiple
              @change="onFileChange"
              accept="image/x-png,image/gif,image/jpeg"
            />
          </b-col>
        </b-row>
        <div ref="paymentForm">
          <b-row class="mt-3">
            <b-col>
              <b-form-group>
                <label
                  >เลือกธนาคารปลายทาง
                  <small
                    v-if="
                      isValidate &&
                        orderTransferObj.bankAccountId === 0 &&
                        selectedApprove
                    "
                    style="color:red;"
                    >กรุณากรอกข้อมูล</small
                  ></label
                >
                <b-form-radio
                  v-for="(item, index) in bankAccountLists"
                  :key="index + 'bankRadio'"
                  :value="item.id"
                  :id="index + 'bankRadio'"
                  name="bankAccountId"
                  v-model="orderTransferObj.bankAccountId"
                  class="my-1 py-3 pl-5 border rounded"
                  :disabled="orderStatus !== 3 || !permission"
                >
                  <img
                    class="mx-2 align-baseline"
                    :src="item.imageUrl"
                    style="width:30px;height:30px"
                  />
                  <div class="d-inline-block">
                    <div>{{ item.bankName }} ({{ item.accountNo }})</div>
                    <div class="text-small textc-primary">
                      {{ item.accountName }}
                    </div>
                  </div>
                </b-form-radio>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="input-group-total">
                <label for="input-total"
                  >จำนวนเงิน
                  <small
                    v-if="isValidate && !transferAmount && selectedApprove"
                    style="color:red;"
                    >กรุณากรอกข้อมูล</small
                  ></label
                >
                <b-form-input
                  id="input-total"
                  v-model="transferAmount"
                  type="number"
                  :disabled="orderStatus !== 3 || !permission"
                  placeholder=""
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group id="input-group-date">
                <label for="input-date"
                  >วันที่โอน
                  <small
                    v-if="
                      isValidate &&
                        !orderTransferObj.transferDate &&
                        selectedApprove
                    "
                    style="color:red;"
                    >กรุณากรอกข้อมูล</small
                  ></label
                >
                <datetime
                  input-class="form-control"
                  type="date"
                  placeholder="กรุณาเลือกวันที่"
                  value-zone="Asia/Bangkok"
                  zone="Asia/Bangkok"
                  format="dd/MM/yyyy"
                  :disabled="orderStatus !== 3 || !permission"
                  v-model="orderTransferObj.transferDate"
                ></datetime>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group id="input-group-time">
                <label for="input-time"
                  >เวลาที่โอน
                  <small
                    v-if="
                      isValidate &&
                        !orderTransferObj.transferTime &&
                        selectedApprove
                    "
                    style="color:red;"
                    >กรุณากรอกข้อมูล</small
                  ></label
                >
                <datetime
                  input-class="form-control"
                  type="time"
                  format="HH:mm:ss"
                  v-model="orderTransferObj.transferTime"
                  placeholder="กรุณาเลือกเวลา"
                  :disabled="orderStatus !== 3 || !permission"
                ></datetime>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="orderStatus !== 8">
            <b-col cols="12">
              <div>
                รายละเอียดเพิ่มเติม
              </div>
            </b-col>
          </b-row>
          <b-row v-if="orderStatus === 8" class="mb-2">
            <b-col cols="12">
              <div>
                เหตุผลในการปฏิเสธการชำระเงิน
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="orderStatus == 3">
            <b-col>
              <div>
                <textarea
                  class="form-control"
                  :disabled="orderStatus !== 3 || !permission"
                  v-model="orderSelectedNote"
                  rows="3"
                ></textarea>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="orderStatus !== 3 && orderStatus !== 8">
            <b-col>
              <div>
                <textarea
                  class="form-control"
                  :disabled="orderStatus !== 3 || !permission"
                  v-model="orderSelectedNote"
                  rows="3"
                ></textarea>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="orderStatus !== 3 && orderStatus === 8">
            <b-col>
              <div>
                <textarea
                  class="form-control"
                  :disabled="orderStatus !== 3 || !permission"
                  v-model="reasonNote"
                  rows="3"
                ></textarea>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col> </b-col>
          </b-row>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div class="w-100">
          <div class="float-right">
            <b-button
              v-if="orderStatus === 3 && permission"
              variant="danger"
              class="mx-2"
              @click="isModalReason = true"
            >
              ปฏิเสธ
            </b-button>
            <b-button
              v-else
              class="bg-color-black text-white"
              @click="setShowModalPayment(false)"
            >
              ปิด
            </b-button>
            <b-button
              v-if="orderStatus === 3 && permission"
              variant="success"
              class="text-white"
              @click="onApprovePayment()"
            >
              อนุมัติ
            </b-button>
          </div>
        </div>
        <div class="float-left"></div>
      </template>
    </b-modal>
    <b-modal
      id="modal-reject-reason"
      size="sm"
      v-model="isModalReason"
      title="ปฏิเสธการชำระเงิน"
      @hide="onHideModalReason()"
    >
      <b-row>
        <b-col>
          เหตุผลในการปฏิเสธการชำระเงิน <br />
          <small style="color:red;" v-if="isValidate && resonSelected !== '0'">
            กรุณาเลือกเหตุผล
          </small>
        </b-col>
      </b-row>
      <b-row class="ml-2 mt-2">
        <b-col>
          <b-form-radio-group
            v-model="resonSelected"
            :options="reasonLists"
            class="mb-3"
            value-field="id"
            text-field="name"
            stacked
            disabled-field="notEnabled"
          >
            <b-form-radio v-model="resonSelected" value="0"
              >อื่นๆ
              <small
                v-if="isValidate && resonSelected === '0' && !orderNote"
                class="text-danger"
                >กรุณาระบุรายละเอียดเพิ่มเติม</small
              ></b-form-radio
            >
            <b-row v-if="resonSelected === '0'" class="ml-2 mt-2">
              <b-col>
                <b-form-textarea
                  id="textarea-note"
                  v-model="orderNote"
                ></b-form-textarea>
              </b-col>
            </b-row>
          </b-form-radio-group>
        </b-col>
      </b-row>
      <template v-slot:modal-footer>
        <div class="w-100">
          <div class="float-right">
            <b-button
              variant="secondary"
              class="mx-2"
              @click="onHideModalReason()"
            >
              ยกเลิก
            </b-button>
            <b-button
              variant="primary"
              class="text-white"
              @click="onRejectPayment()"
            >
              ตกลง
            </b-button>
          </div>
        </div>
        <div class="float-left"></div>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import TextInput from "../components/TextInput";
import { Datetime } from "vue-datetime";
import * as moment from "moment/moment";
import { mapGetters } from "vuex";
export default {
  name: "AddPayment",
  components: {
    // TextInput
    datetime: Datetime
  },
  props: {
    buyerInfo: Object,
    isShow: {
      required: false,
      type: Boolean
    },
    transaction: Object,
    onUpdatedPayment: Function,
    grandTotal: Number,
    orderStatus: Number,
    note: String,
    reasonNote: String,
    scrollToBottomFlag: Boolean
  },
  data() {
    return {
      filter: {
        page: 1,
        search: "",
        take: 20
      },
      isModalReason: false,
      file: [],
      url: [],
      transferTime: null,
      defaultDate: "",
      defaultTime: "",
      options: [
        { value: null, text: "-- กรุณายืนยันการชำระเงิน --", disabled: true },
        { value: "approve", text: "ยืนยันการชำระเงิน" },
        { value: "disapprove", text: "ไม่ยืนยันการชำระเงิน" }
      ],
      address: null,
      isValidate: false,
      isSamePayment: false,
      imageIndex: null,
      imageList: [],
      validateMessage: "",
      selectedApprove: false,
      fileNameLists: [],
      imageIdLists: [],
      totalAmount: this.grandTotal,
      orderNote: "",
      reasonLists: [],
      resonSelected: null,
      orderTransferObj: null,
      orderTransferSlip: null,
      orderSelectedNote: this.note,
      bankAccountLists: []
    };
  },
  created() {
    this.getRejectReason();
    this.getBanks();
    this.getUploadSlip();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.scrollToBottomFlag) {
        setTimeout(() => {
          this.$refs.paymentForm.scrollIntoView();
        }, 500);
      }
    });
  },
  computed: {
    isModalShow: {
      get: function() {
        return this.isShow;
      },
      set: function() {}
    },
    transferAmount: {
      get: function() {
        return this.orderTransferSlip.amount || this.grandTotal;
      },
      set: function(value) {
        this.orderTransferSlip.amount = value;
      }
    },

    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      permission: "getPermission"
    })
  },
  methods: {
    moment: function() {
      return moment();
    },
    getBanks() {
      this.$axios
        .get(this.$baseUrl + "/api/payment/bankaccount", {
          params: { transactionId: this.transaction.id }
        })
        .then(response => {
          if (response.data.result === 1) {
            this.bankAccountLists = response.data.detail;
          }
        });
    },
    getUploadSlip() {
      this.$axios
        .get(
          this.$baseUrl +
            "/api/transaction/" +
            this.transaction.id +
            "/transferSlip"
        )
        .then(response => {
          if (response.data.result === 1) {
            this.orderTransferSlip = response.data.detail;

            if (this.orderTransferSlip.bankAccountId !== 0) {
              this.orderTransferObj = this.orderTransferSlip;
            } else {
              let objValue = Object.assign({}, this.orderTransferSlip);
              objValue.transferDate = null;
              objValue.transferTime = null;
              this.orderTransferObj = objValue;
            }
            // console.log(this.orderTransferObj);
          }
        });
    },
    getRejectReason: async function() {
      await this.$axios
        .get(this.$baseUrl + "/api/transaction/rejectInfo")
        .then(response => {
          if (response.data.result === 1) {
            this.reasonLists = response.data.detail.reasonList;
          } else {
            alert(response.data.message);
          }
        });
    },
    toBase64(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.imageList.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    onHandleApprove(value) {
      if (value) {
        this.selectedApprove = value;
      }
    },
    onFileChange(e) {
      if (e.target.files.length > 0) {
        const file = e.target.files;
        if (this.imageIndex > -1) {
          this.imageList.splice(this.imageIndex, 1);
          this.fileNameLists.splice(this.imageIndex, 1);
        }
        file.forEach(element => {
          this.toBase64(element);
          this.fileNameLists.push(element.name);
        });
        this.imageIdLists.forEach(element => {
          var indexTarget = this.orderTransferObj.imageList.findIndex(
            item => item.id === element
          );
          this.orderTransferObj.imageList.splice(indexTarget, 1);
        });
      }
    },
    validate() {
      if (this.selectedApprove === false) {
        if (!this.resonSelected) return false;
        if (this.resonSelected && this.resonSelected === "0") {
          if (!this.orderNote) return false;
        }
        return true;
      } else {
        if (this.orderTransferObj.bankAccountId === 0) return false;
        if (!this.transferAmount) return false;
        if (this.transferAmount && this.transferAmount === 0) return false;
        if (!this.orderTransferObj.transferDate) return false;
        if (!this.orderTransferObj.transferTime) return false;
        if (
          this.imageList.length === 0 &&
          this.orderTransferObj.imageList.length === 0
        )
          return false;
      }

      return true;
    },
    setShowModalPayment(value, updateFlag) {
      this.$emit("setShowModalPayment", value, updateFlag);
      this.imageList = [];
      this.imageIdLists = [];
      this.selectedApprove = false;
    },
    onEditImageFromDB(editFlag, index) {
      if (editFlag) {
        this.clickImportImage(-1);
        this.imageIdLists.push(this.orderTransferObj.imageList[index].id);
      } else {
        this.imageIdLists.push(this.orderTransferObj.imageList[index].id);
        this.orderTransferObj.imageList.splice(index, 1);
      }
    },
    onHideModalReason() {
      this.isModalReason = false;
      this.resonSelected = null;
      this.isValidate = false;
    },
    onDeleteImageItem(index) {
      this.imageList.splice(index, 1);
      this.fileNameLists.splice(this.imageIndex, 1);
    },
    onRejectPayment() {
      this.isValidate = true;
      this.selectedApprove = false;
      this.note = this.orderSelectedNote;
      if (this.validate()) {
        this.isValidate = false;
        if (this.resonSelected === "0") {
          this.onUpdatedPayment(
            this.orderTransferObj,
            this.imageList,
            this.imageIdLists,
            this.selectedApprove,
            this.orderSelectedNote,
            this.orderNote,
            0
          );
        } else {
          var reasonName = this.reasonLists.find(
            item => item.id === this.resonSelected
          ).name;
          this.onUpdatedPayment(
            this.orderTransferObj,
            this.imageList,
            this.imageIdLists,
            this.selectedApprove,
            this.orderSelectedNote,
            reasonName,
            this.resonSelected
          );
        }
        this.onHideModalReason();
      }
    },
    onApprovePayment() {
      this.isValidate = true;
      this.selectedApprove = true;
      if (this.validate()) {
        this.onUpdatedPayment(
          this.orderTransferObj,
          this.imageList,
          this.imageIdLists,
          this.selectedApprove,
          this.orderSelectedNote
        );
        this.isValidate = false;
      }
    },
    handleSamePaymentChange() {
      this.isSamePayment = !this.isSamePayment;
    },
    clickImportImage(index) {
      this.$refs.inputImageFile.click();
      this.imageIndex = index;
    }
  }
};
</script>

<style lang="scss" scoped>
.img-container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
</style>
