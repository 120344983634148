<template>
  <b-modal
    v-model="isModalShow"
    title="ข้อมูลลูกค้า"
    hide-footer
    scrollable
    @hide="setShowModalAddress(false, false)"
  >
    <div>
      <b-row>
        <b-col>
          <AddressUserForm
            :form="orderSelected.buyerInfo.userInfo"
            :v="v.orderSelected.buyerInfo.userInfo"
            :getUserInfoByTelephone="getUserInfoByTelephone"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="mb-2 text-color">ที่อยู่ในการจัดส่ง</div>
        </b-col>
      </b-row>
      <div>
        <div class="form-check">
          <input
            v-model="isLocalSelfPick"
            type="checkbox"
            class="form-check-input"
            id="getIsSelfPickCheckbox"
          />
          <label
            class="form-check-label text-color mb-2"
            for="getIsSelfPickCheckbox"
            >รับสินค้าด้วยตัวเอง</label
          >
        </div>
        <b-card>
          <div v-if="isDisplayFormShippingAddress" class="panel-address">
            <template v-if="isLocalSelfPick">
              <InputTextArea
                class=""
                type="text"
                textFloat=""
                placeholder="กรอกที่อยู่ในการรับสินค้า"
                name="text"
                rows="5"
                v-model="orderSelected.selfPickingLocation"
                :v="v.orderSelected.selfPickingLocation"
              />
            </template>
            <template v-else>
              <AddressForm
                :form="orderSelected.buyerInfo.shippingAddress"
                :v="v.orderSelected.buyerInfo.shippingAddress"
                type="shipping"
                :handleAddress="handleShippingAddress"
                :data="orderSelected"
            /></template>
          </div>
          <div class="d-flex" v-else>
            <template v-if="isLocalSelfPick">
              <div>{{ orderSelected.selfPickingLocation }}</div>
            </template>
            <template v-else>
              <div>
                <div>{{ orderSelected.buyerInfo.shippingAddress.address }}</div>
                <div>
                  {{ orderSelected.buyerInfo.shippingAddress.road }}
                  {{ orderSelected.buyerInfo.shippingAddress.subdistrict }}
                </div>
                <div>
                  {{ orderSelected.buyerInfo.shippingAddress.district }}
                  {{ orderSelected.buyerInfo.shippingAddress.province }}
                </div>
                <div>{{ orderSelected.buyerInfo.shippingAddress.zipcode }}</div>
              </div>
            </template>
            <div class="ml-auto">
              <div
                @click="isDisplayFormShippingAddress = true"
                class="p-0 mt-auto mb-1 cursor-pointer"
              >
                <font-awesome-icon icon="edit" />
                แก้ไข
              </div>
            </div>
          </div>
        </b-card>
      </div>
      <div class="d-flex">
        <div class="form-check">
          <input
            v-model="data.buyerInfo.isRequestTax"
            type="checkbox"
            class="form-check-input"
            id="getTaxReceiptCheckbox"
            @change="isSelect"
          />
          <label class="form-check-label text-color" for="getTaxReceiptCheckbox"
            >ออกใบกำกับภาษี</label
          >
        </div>
      </div>

      <div class="my-2 text-color">ที่อยู่ในการออกใบกำกับภาษี</div>
      <b-card>
        <div v-if="isDisplayFormBillingAddress" class="panel-address">
          <AddressForm
            :form="orderSelected.buyerInfo.billingAddress"
            :v="v.orderSelected.buyerInfo.billingAddress"
            type="billing"
            :handleAddress="handleBillingAddress"
            :data="orderSelected"
          />
        </div>
        <div class="d-flex" v-else>
          <div>
            <div>{{ orderSelected.buyerInfo.billingAddress.address }}</div>
            <div>
              {{ orderSelected.buyerInfo.billingAddress.road }}
              {{ orderSelected.buyerInfo.billingAddress.subdistrict }}
            </div>
            <div>
              {{ orderSelected.buyerInfo.billingAddress.district }}
              {{ orderSelected.buyerInfo.billingAddress.province }}
            </div>
            <div>{{ orderSelected.buyerInfo.billingAddress.zipcode }}</div>
          </div>
          <div class="ml-auto">
            <div
              @click="isDisplayFormBillingAddress = true"
              class="p-0 mt-auto mb-1 cursor-pointer"
            >
              <font-awesome-icon icon="edit" />
              แก้ไข
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div>
      <b-row>
        <b-col>
          <b-button
            v-if="orderStatus === 5 || orderStatus === 7"
            pill
            class="btn bg-dark my-2 text-white w-100"
            @click="setShowModalAddress(false)"
            >ปิด</b-button
          >
          <b-button
            v-else
            pill
            class="btn bg-color-primary my-2 text-white w-100"
            @click="onUpdatedAddress(isLocalSelfPick)"
            >แก้ไขข้อมูล</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import AddressForm from "@/components/order/component/OrderAddressDetailForm";
import AddressUserForm from "@/components/order/component/OrderUserDetailForm";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  name: "ModalAddressDetail",
  components: {
    AddressForm,
    AddressUserForm,
    InputTextArea
  },
  props: {
    orderSelected: Object,
    isShow: {
      required: false,
      type: Boolean
    },
    orderStatus: {
      required: false,
      type: Number
    },
    v: {
      required: true,
      type: Object
    },
    onUpdatedAddress: Function,
    isSelfPickup: Boolean
  },
  data() {
    return {
      data: this.orderSelected,
      isModalShow: false,
      isDisplayFormShippingAddress: false,
      isDisplayFormBillingAddress: false,
      isLocalSelfPick: false
    };
  },
  created() {
    this.data = this.orderSelected;
    this.isModalShow = this.isShow;

    if (!this.orderSelected.buyerInfo.shippingAddress.address)
      this.isDisplayFormShippingAddress = true;

    if (!this.orderSelected.buyerInfo.billingAddress.address)
      this.isDisplayFormBillingAddress = true;

    this.isLocalSelfPick = this.isSelfPickup;
    this.selfPickingLocation = JSON.parse(
      JSON.stringify(this.orderSelected.selfPickingLocation)
    );
  },
  computed: {
    ...mapGetters({
      theOneCardFlag: "getTheOneCardFlag"
    })
  },
  methods: {
    isSelect() {
      this.isDisplayFormBillingAddress = this.data.buyerInfo.isRequestTax;
    },
    setShowModalAddress() {
      this.$emit("setShowModalAddress");
    },
    handleShippingAddress(val, address) {
      if (val) this.data.buyerInfo.sameAddress = val;

      if (this.data.buyerInfo.sameAddress) {
        this.data.buyerInfo.billingAddress.firstname = address.firstname;
        this.data.buyerInfo.billingAddress.lastname = address.lastname;
        this.data.buyerInfo.billingAddress.address = address.address;
        this.data.buyerInfo.billingAddress.road = address.road;
        this.data.buyerInfo.billingAddress.subdistrict = address.subdistrict;
        this.data.buyerInfo.billingAddress.district = address.district;
        this.data.buyerInfo.billingAddress.province = address.province;
        this.data.buyerInfo.billingAddress.zipcode = address.zipcode;
        this.data.buyerInfo.billingAddress.zipcodeExternalId =
          address.zipcodeExternalId;
      }
    },
    handleBillingAddress() {
      this.data.buyerInfo.sameAddress = false;
    },
    getUserInfoByTelephone() {
      const tel = this.data.buyerInfo.userInfo.telephone;

      if (tel && tel.length === 10) {
        this.$axios
          .post(
            `${this.$baseUrl}/api/user/${tel}/${this.data.buyerInfo.userInfo.socialSourceId}`
          )
          .then(response => {
            if (response.data.result === 1) {
              this.data.buyerInfo.billingAddress =
                response.data.detail.billingAddress;
              this.data.buyerInfo.shippingAddress =
                response.data.detail.shippingAddress;
              this.data.buyerInfo.userInfo = response.data.detail.userInfo;
              this.data.buyerInfo.isConsent = response.data.detail.isConsent;
              this.data.buyerInfo.sameAddress =
                response.data.detail.sameAddress;
            }
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.name-title {
  border: none;
  border-bottom: 2px solid #ebebeb;
  outline: none;
  box-shadow: none;
}
.text-color {
  color: #575757;
}
</style>
