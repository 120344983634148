var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rounded shadow-sm p-3 bg-color-secondary d-flex"},[_c('div',{staticClass:"my-auto"},[_c('img',{staticClass:"product-image",attrs:{"src":_vm.product.imageUrl}})]),_c('div',{staticClass:"ml-2 w-100"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"line-clamp"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"d-flex ml-auto"},[_c('div',[_c('font-awesome-icon',{class:[
              { disabled: !(_vm.orderStatus === 1 && _vm.product.quantity > 0) }
            ],attrs:{"icon":"minus-circle","size":"lg"},on:{"click":function($event){return _vm.removeProductQuantity(_vm.product)}}})],1),_c('div',{staticClass:"mx-2 f-size-16"},[_vm._v(_vm._s(_vm.product.quantity))]),_c('div',[_c('font-awesome-icon',{class:[
              {
                disabled: !(
                  _vm.orderStatus == 1 &&
                  _vm.product.quantity < _vm.product.availableQuantity
                )
              }
            ],attrs:{"icon":"plus-circle","size":"lg"},on:{"click":function($event){return _vm.addProductQuantity(_vm.product)}}})],1)])]),_c('div',{staticClass:"f-size-14 text-secondary"},[_vm._v(" "+_vm._s(_vm.product.barcode)+" ( "+_vm._s(_vm.product.sku || "-")+" ) ")]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"f-size-14 text-secondary"},[_vm._v(" "+_vm._s(_vm._f("numeral")(_vm.product.price,"0,0.00"))+" x "+_vm._s(_vm.product.quantity)+" ")]),_c('div',{staticClass:"ml-auto f-size-14"},[(_vm.product.discountStikePrice !== 0)?[_c('del',{staticClass:"text-color-grey"},[_vm._v(" "+_vm._s(_vm._f("numeral")(_vm.product.total,"0,0.00"))+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm._f("numeral")(_vm.product.stikePrice,"0,0.00"))+" ")])]:[_vm._v(" "+_vm._s(_vm._f("numeral")(_vm.product.total,"0,0.00"))+" ")]],2)]),(_vm.product.displayPromotions && _vm.product.displayPromotions.length > 0)?_c('div',{staticClass:"mt-3 f-size-14 text-secondary"},[_vm._m(0),_c('div',{staticClass:"mt-2"},_vm._l((_vm.product.displayPromotions),function(promotion,index){return _c('div',{key:index},[_c('div',{staticClass:"text-small text-left pl-1"},[_c('div',[_vm._v(_vm._s(index + 1)+". "+_vm._s(promotion.name))]),_c('div',[_vm._v(" ("+_vm._s(_vm._f("moment")(promotion.startDate,_vm.$formatDate))+" - "+_vm._s(_vm._f("moment")(promotion.endDate,_vm.$formatDate))+") ")])])])}),0)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_vm._v("โปรโมชั่น")])])
}]

export { render, staticRenderFns }