<template>
  <div>
    <b-modal
      v-model="modalShow"
      :title="selectedPromotion.promotionName"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <div class="mb-2 text-error f-size-14">
        {{ validateMessage }}
      </div>
      <div
        v-for="(freeproduct, findex) in selectedPromotion.products"
        :key="findex"
      >
        <div class="rounded shadow-sm py-2 px-3 bg-color-secondary my-1 d-flex">
          <div>
            <b-form-checkbox
              v-model="freeproduct.selected"
              name="freeproduct-button"
              :disabled="freeproduct.isApply"
              @change="onChangeProductSelected"
            >
            </b-form-checkbox>
          </div>
          <div class="my-auto">
            <div
              class="product-image my-2"
              v-bind:style="{
                'background-image': 'url(' + freeproduct.imageUrl + ')'
              }"
            ></div>
          </div>
          <div class="ml-2 my-2 w-100">
            <div class="d-flex">
              <div class="line-clamp">{{ freeproduct.name }}</div>
              <div class="ml-auto">{{ freeproduct.quantity }}</div>
            </div>

            <div class="f-size-14 text-secondary">
              {{ freeproduct.barcode }} ( {{ freeproduct.sku || "-" }} )
            </div>

            <div class="d-flex">
              <div class="f-size-14 text-secondary">
                {{ freeproduct.price | numeral("0,0.00") }} x
                {{ freeproduct.quantity }}
              </div>
              <div class="ml-auto f-size-14">ฟรี</div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-4">
        <!-- <b-button @click="hide">ยกเลิก</b-button> -->
        <b-button
          @click="onSubmitFreeProduct"
          class="bg-color-primary text-white ml-2"
          >ตกลง</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    selectedPromotion: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      modalShow: false,
      validateMessage: "",
      isError: ""
    };
  },
  computed: {
    selectedPromotionDisplay() {
      return JSON.parse(JSON.stringify(this.selectedPromotion));
    }
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    hide() {
      this.modalShow = false;
    },
    onSubmitFreeProduct() {
      if (this.onValidateFreeProduct()) {
        this.validateMessage = `กรุณาเลือกของแถมจำนวน ${this.selectedPromotionDisplay.limitProduct} ชิ้น`;
        this.isError = true;
        return;
      }

      this.$emit("onSubmitFreeProduct", this.selectedPromotionDisplay);
      this.hide();
    },
    onChangeProductSelected() {
      if (this.onValidateFreeProduct() && this.isError) {
        this.validateMessage = `กรุณาเลือกของแถมจำนวน ${this.selectedPromotionDisplay.limitProduct} ชิ้น`;
      } else {
        this.validateMessage = "";
      }
    },
    onValidateFreeProduct() {
      let count = 0;
      this.selectedPromotionDisplay?.products.forEach(product => {
        if (product.selected) {
          count = count + product.quantity;
        }
      });
      if (this.selectedPromotionDisplay) {
        this.selectedPromotionDisplay.poductSelectedCount = count;
      }
      if (count !== this.selectedPromotionDisplay.limitProduct) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product-image {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.text-error {
  color: red;
}
</style>
