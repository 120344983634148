<template>
  <div :class="{ 'panel-overflow': !isMobile }" v-if="orderSelected">
    <div class="" v-if="!isMobile">
      <div
        class="d-flex mx-3 mt-3 justify-content-between color-primary"
        style="border-left: 5px solid"
      >
        <div class="ml-2 my-2">
          <div class="text-big text-black">รายละเอียดลูกค้า</div>
        </div>
        <div class="my-auto">
          <!-- <div
            v-if="
              [1, 2, 3].includes(orderSelected.saleChannel) &&
                orderSelected.statusId == 1
            "
          >
            <b-button
              class="text-white customer-btn"
              @click="setShowModalDisplayCustomer(true)"
            >
              <font-awesome-icon
                icon="user-circle"
                size="lg"
                class="text-white mr-2"
              />
              รายชื่อลูกค้า</b-button
            >
          </div> -->
        </div>
      </div>
    </div>
    <div class="d-flex mx-3 mt-3">
      <div class="my-auto font-weight-bold">
        <span>ข้อมูลลูกค้า </span>
      </div>
      <div
        class="ml-auto"
        :class="[
          orderSelected.id == 0 || orderSelected.statusId == 1
            ? 'text-secondary  disabled'
            : 'pointer'
        ]"
        v-if="orderSelected.statusId == 2 || orderSelected.statusId == 3"
      >
        <span @click="setShowModalAddress(true)">
          <u>แก้ไข</u>
        </span>
      </div>
    </div>
    <div class="mx-3 mt-2">
      <OrderAddressDetail
        :buyerInfo="orderSelected.buyerInfo"
        :socialInfo="socialInfo"
        :orderStatusName="orderSelected.statusName"
        :orderStatusId="orderSelected.statusId"
        :orderShippingTypeId="orderSelected.shippingTypeId"
        :orderShippingLocation="orderSelected.selfPickingLocation"
        v-on:setShowModalAddress="setShowModalAddress"
      />
    </div>
    <div
      class="d-flex mx-3 mt-3"
      v-if="
        orderSelected.paymentMethodId == 1 &&
          [2, 3].includes(orderSelected.statusId)
      "
    >
      <div class="my-auto">
        <span>หลักฐานการโอนเงิน </span>
      </div>
      <div class="ml-auto">
        <b-button
          class="text-white customer-btn"
          @click="setShowModalPayment(true)"
        >
          ตรวจสอบหลักฐานการโอนเงิน
        </b-button>
      </div>
    </div>
    <div class="mx-3 mt-3">
      <div class="my-auto">
        <span>รายละเอียดการชำระเงิน</span>
      </div>
    </div>

    <div class="mx-3 mt-3">
      <OrderPaymentDetail
        v-if="forceRefresh && orderSelected.id"
        :transaction="orderSelected"
        :setShowModalPayment="setShowModalPayment"
      />
    </div>
    <div class="d-flex mx-3 mt-3">
      <div class="my-auto">
        <span>รายละเอียดเพิ่มเติม</span>
      </div>
      <div class="ml-auto">
        <span class="pointer" @click="setShowModalDisplayNote(true)">
          <u>แก้ไข</u>
        </span>
      </div>
    </div>
    <div class="mx-3 mt-2">
      <textarea
        class="form-control text-color-black"
        v-model="orderSelected.note"
        disabled
        rows="3"
      ></textarea>
    </div>

    <div
      v-if="orderSelected.statusId === 7 && orderSelected.reasonNote !== ''"
      class="mb-4 mx-3 mt-3"
    >
      <div class="text-danger">
        ยกเลิกคำสั่งซื้อเนื่องจาก :
        {{ orderSelected.reasonNote }}
      </div>
    </div>
    <!-- <div v-if="isMobile" class="mx-3" :class="{ 'mb-200': isMobile }">
      <button
        class="btn py-3 mt-2 w-100 rounded-pill"
        style="background-color: transparent; color: black; border: solid 1px"
        @click="onBackToOrderListPage()"
      >
        <span>ย้อนกลับ</span>
      </button>
    </div>
    <div
      :class="['mb-4', { 'mb-200': isMobile }]"
      v-if="orderSelected.statusId !== 7"
    ></div> -->
    <ModalAddressDetail
      v-if="forceRefresh"
      v-bind:isShow="isShowModalAddress"
      v-on:setShowModalAddress="setShowModalAddress"
      v-bind:orderSelected="orderSelected"
      v-bind:orderStatus="orderSelected.statusId"
      v-bind:v="$v"
      :isSelfPickup="isSelfPickup"
      :onUpdatedAddress="onUpdatedAddress"
    />

    <ModalPaymentDetail
      v-if="orderSelected.orderId"
      ref="modalPaymentDetail"
      :orderId="orderSelected.orderId"
      :transactionId="orderSelected.id"
      :invoiceNo="orderSelected.invoiceNo"
      :orderTotalAmount="orderSelected.grandTotal"
      :orderStatus="orderSelected.statusId"
      v-on:onRefreshData="onRefreshData"
      v-on:handleForcrrefresh="handleForcrrefresh"
    />

    <ModalPaymentForm
      ref="paymentContent"
      v-if="forceRefresh && orderSelected.orderId"
      v-bind:isShow="isShowModalPayment"
      v-on:setShowModalPayment="setShowModalPayment"
      :transaction="orderSelected"
      :onUpdatedPayment="onUpdatedPayment"
      :grandTotal="orderSelected.grandTotal"
      :scrollToBottomFlag="scrollToBottomFlag"
      :orderStatus="orderSelected.statusId"
      :note="orderSelected.note"
      :reasonNote="orderSelected.reasonNote"
    />
    <ModalMessage ref="modalMessage" :message="message" />
    <ModalNoteDetail
      v-if="forceRefresh"
      v-bind:onSaveNote="onSaveNoteByOrder"
      v-bind:orderSelected="orderSelected"
      v-bind:isShow="isShowModalNote"
      v-on:setShowModalDisplayNote="setShowModalDisplayNote"
    />
    <ModalSelectCustomer
      ref="modalSelecteCustomer"
      :saleChannel="orderSelected.saleChannel"
      v-on:onSelectedCustomer="onSelectedCustomer"
    />
    <ModalUploadSlip
      ref="modalUploadSlip"
      :orderId="orderSelected.id"
      :invoiceNo="orderSelected.invoiceNo"
      :orderTotalAmount="orderSelected.grandTotal"
      :orderStatus="orderSelected.statusId"
      v-on:onRefreshData="onRefreshData"
    />
  </div>
</template>

<script>
import OrderAddressDetail from "@/components/order/component/OrderAddressDetail";
import OrderPaymentDetail from "@/components/order/component/OrderPaymentDetail";
import ModalAddressDetail from "@/components/modal/ModalAddressDetail";
import ModalPaymentForm from "@/components/modal/ModalPaymentForm";
import ModalMessage from "@/components/modal/ModalMessage";
import ModalSelectCustomer from "@/components/modal/ModalSelectCustomer";
import ModalNoteDetail from "@/components/modal/ModalNoteDetail";
import ModalPaymentDetail from "./modal/ModalPaymentDetail.vue";
import ModalUploadSlip from "./modal/ModalUploadSlip.vue";
import * as moment from "moment/moment";

import { mapGetters } from "vuex";
import {
  required,
  numeric,
  minValue,
  helpers,
  email,
  minLength,
  requiredIf
} from "vuelidate/lib/validators";

const alpha = helpers.regex("alpha", /^[ก-๙a-zA-Z]*$/);
const valueDefault = (value, flag) => {
  if (value == 0 && flag) {
    return false;
  } else {
    return true;
  }
};
export default {
  props: {
    orderSelected: {
      required: true,
      type: Object
    },
    getOrderInformationById: {
      required: true,
      type: Function
    },
    isMobile: {
      required: true,
      type: Boolean
    }
  },
  components: {
    OrderAddressDetail,
    ModalAddressDetail,
    OrderPaymentDetail,
    ModalPaymentForm,
    ModalMessage,
    ModalNoteDetail,
    ModalSelectCustomer,
    ModalPaymentDetail,
    ModalUploadSlip
  },
  data() {
    return {
      forceRefresh: true,
      isShowModalAddress: false,
      isShowModalPayment: false,
      isShowModalNote: false,
      scrollToBottomFlag: false,
      message: "",
      socialInfo: {},
      isSelfPickup: false
    };
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      isStore: "getStoreFlag",
      isShowModalOverLay: "getIsLoading",
      mainColor: "getBrandColor"
    })
  },
  validations() {
    return {
      orderSelected: {
        buyerInfo: {
          userInfo: {
            firstname: { required, alpha },
            lastname: { required, alpha },
            email: { email },
            telephone: { required, minLength: minLength(9) }
          },
          shippingAddress: {
            firstname: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              }),
              alpha
            },
            lastname: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              }),
              alpha
            },
            telephone: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              }),
              minLength: minLength(9)
            },
            address: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              })
            },
            district: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              })
            },
            subdistrict: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              })
            },
            province: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              })
            },
            zipcode: {
              required: requiredIf(function() {
                return !this.isSelfPickup;
              }),
              numeric,
              minValue: minValue(5)
            }
          },

          billingAddress: {
            nameTitleId: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.isRequestTax;
              }),
              valueDefault(value) {
                return valueDefault(
                  value,

                  this.orderSelected.buyerInfo.isRequestTax
                );
              }
            },

            firstname: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.sameAddress !== true;
              }),
              alpha
            },
            lastname: {
              required: requiredIf(function() {
                return (
                  this.orderSelected.buyerInfo.sameAddress !== true // && this.orderSelected.buyerInfo.billingAddress.nameTitleId !== 4
                );
              }),
              alpha
            },

            address: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.sameAddress !== true;
              })
            },
            district: {
              required: requiredIf(function() {
                return !this.orderSelected.buyerInfo.sameAddress;
              })
            },
            subdistrict: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.sameAddress !== true;
              })
            },
            province: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.sameAddress !== true;
              })
            },
            zipcode: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.sameAddress !== true;
              }),
              numeric,
              minValue: minValue(5)
            },
            tax: {
              required: requiredIf(function() {
                return this.orderSelected.buyerInfo.isRequestTax == true;
              }),
              numeric,
              minValue: minValue(13)
            }
          }
        },
        selfPickingLocation: {
          required: requiredIf(function() {
            return this.isSelfPickup;
          })
        }
      }
    };
  },
  created() {
    this.isSelfPickup = Boolean(this.orderSelected.shippingTypeId === 7);
  },
  methods: {
    setShowModalUploadSlip() {
      this.$refs.modalUploadSlip.show();
    },
    onRefreshData() {
      this.getOrderInformationById(this.orderSelected.id);
    },
    onSelectedCustomer(item) {
      let customer = Object.assign(item);
      this.socialInfo = Object.assign(item);
      this.getBuyerInfoBySocialReferenceId(customer);
      this.setShowModalDisplayCustomer(false);
    },
    getBuyerInfoBySocialReferenceId(customer) {
      this.$axios
        .get(this.$baseUrl + "/api/user/address/" + customer.socialReferenceId)
        .then(response => {
          if (response.data.result == 1) {
            this.orderSelected.buyerInfo = response.data.detail;
            this.orderSelected.buyerInfo.userInfo.profilePictureUrl =
              customer.profilePictureUrl;
            this.orderSelected.buyerInfo.userInfo.socialName =
              customer.socialName;
          } else {
            this.onSetSocialAddress(customer);
          }
        });
    },
    onSetSocialAddress(customer) {
      this.orderSelected.buyerInfo = {
        userInfo: {
          telephone: null,
          firstname: null,
          lastname: null,
          email: null,
          socialMediaId: 0,
          socialMediaName: null,
          socialReferenceId: null
        },
        shippingAddress: {
          firstname: null,
          lastname: null,
          address: null,
          road: null,
          province: null,
          district: null,
          subdistrict: null,
          zipcode: null
        },
        billingAddress: {
          firstname: null,
          lastname: null,
          address: null,
          road: null,
          province: null,
          district: null,
          subdistrict: null,
          zipcode: null
        },
        sameAddress: false
      };

      //set user info
      this.orderSelected.buyerInfo.userInfo.email = customer.email;
      this.orderSelected.buyerInfo.userInfo.firstname = customer.firstname;
      this.orderSelected.buyerInfo.userInfo.lastname = customer.lastname;
      this.orderSelected.buyerInfo.userInfo.socialMediaName =
        customer.socialMediaName;
      this.orderSelected.buyerInfo.userInfo.socialReferenceId =
        customer.socialReferenceId;
      this.orderSelected.buyerInfo.userInfo.socialSalesChannelId = this.orderSelected.saleChannel;
      this.orderSelected.buyerInfo.userInfo.socialSourceId =
        customer.socialSourceId;
      this.orderSelected.buyerInfo.userInfo.telephone = customer.telephone;
      this.orderSelected.buyerInfo.userInfo.profilePictureUrl =
        customer.profilePictureUrl;
      this.orderSelected.buyerInfo.userInfo.socialName = customer.socialName;

      //set user shipping
      this.orderSelected.buyerInfo.shippingAddress.firstname =
        customer.firstname;
      this.orderSelected.buyerInfo.shippingAddress.lastname = customer.lastname;

      //set user billing
      this.orderSelected.buyerInfo.billingAddress.firstname =
        customer.firstname;
      this.orderSelected.buyerInfo.billingAddress.lastname = customer.lastname;
    },
    setShowModalDisplayCustomer(value) {
      if (value) {
        this.$refs.modalSelecteCustomer.show();
      } else {
        this.$refs.modalSelecteCustomer.hide();
      }
    },
    setShowModalDisplayNote(value) {
      this.isShowModalNote = value;
      if (!this.isShowModalNote) {
        this.getOrderInformationById(this.orderSelected.id);
      }
      this.handleForcrrefresh();
    },
    onSaveNoteByOrder() {
      this.$axios
        .post(this.$baseUrl + "/api/transaction/note", {
          OrderId: this.orderSelected.id,
          Note: this.orderSelected.note
        })
        .then(response => {
          if (response.data.result === 1) {
            this.$store.dispatch("setIsLoading", false);
            this.message = "แก้ไขข้อมูลสำเร็จ";
            this.setShowModalDisplayNote(false);
            this.$refs.modalMessage.show();
          } else {
            this.message = response.data.message;
            this.$refs.modalMessage.show();
          }
        });
    },
    // onBackToOrderListPage() {
    //   window.location.href = `/?brandID=${this.$route.query.brandID}${
    //     this.$route.query.isMobile == "true" ? "&isMobile=true" : ""
    //   }`;
    // },
    moment: function() {
      return moment();
    },
    onUpdatedPayment(
      tranferObj,
      imageList,
      imageIdLists,
      approveFlag,
      orderSelectedNote,
      note,
      reasonId
    ) {
      this.$store.dispatch("setIsLoading", true);
      this.imageIdLists = imageIdLists;
      var transferDate = moment(tranferObj.transferDate).format("MM/DD/YYYY");
      var transferTime = moment(new Date(tranferObj.transferTime)).format(
        "HH:mm"
      );
      if (approveFlag === true) {
        this.$axios
          .post(this.$baseUrl + "/api/payment/updateSlipInfo", {
            Amount: tranferObj.amount,
            transactionid: this.orderSelected.id,
            transferDate: transferDate,
            transferTime: transferTime,
            bankAccountId: tranferObj.bankAccountId,
            UploadImage: imageList,
            deleteImage: this.imageIdLists
          })
          .then(response => {
            if (response.data.result === 1) {
              this.setShowModalPayment(false);
              this.onChangeStatus(4, true, false);
              if (note && note !== "") {
                this.onSaveNote(orderSelectedNote);
              }
            }
          });
      } else {
        this.onChangeStatus(8, true, false, reasonId, note);
        this.setShowModalPayment(false);
      }
    },
    onChangeStatus: async function(id, isShowModal, isRestock, reasonId, note) {
      let body = {};
      if (note) {
        body = {
          orderId: this.orderSelected.id,
          statusId: id,
          reStock: isRestock,
          reasonId: reasonId,
          reasonNote: note
        };
      } else {
        body = {
          orderId: this.orderSelected.id,
          statusId: id,
          reStock: isRestock
        };
      }

      await this.$axios
        .post(this.$baseUrl + "/api/transaction/authorizedstatus", body)
        .then(response => {
          if (response.data.result === 1) {
            if (isShowModal === true) {
              if (id === 4) {
                this.message = "ยืนยันการชำระเงินสำเร็จ";
              } else if (id === 8) {
                this.message = "ปฏิเสธการยืนยันการชำระเงินสำเร็จ";
              }
              this.$refs.modalMessage.show();
            }

            this.getOrderInformationById(this.orderSelected.id);
            this.$store.dispatch("setReloadListFlag", true);
          } else {
            this.$store.dispatch("setIsLoading", false);
            this.message = response.data.message;
            this.$refs.modalMessage.show();
          }
        });
    },
    onSaveNote(note) {
      this.$axios
        .post(this.$baseUrl + "/api/transaction/note", {
          OrderId: this.orderSelected.id,
          Note: note
        })
        .then(response => {
          if (response.data.result !== 1) {
            alert(response.data.message);
          }
        });
    },
    setShowModalPayment() {
      if ([2, 3].includes(this.orderSelected.statusId))
        this.$refs.modalPaymentDetail.show();
    },
    handleAddress(isSelfPickup) {
      this.orderSelected.buyerInfo.shippingAddress.firstname = this.orderSelected.buyerInfo.userInfo.firstname;
      this.orderSelected.buyerInfo.shippingAddress.lastname = this.orderSelected.buyerInfo.userInfo.lastname;
      this.orderSelected.buyerInfo.shippingAddress.telephone = this.orderSelected.buyerInfo.userInfo.telephone;

      if (isSelfPickup) this.orderSelected.shippingTypeId = 7;
    },

    onUpdatedAddress(isSelfPickup) {
      this.handleAddress(isSelfPickup);
      this.$v.orderSelected.$touch();
      if (this.$v.orderSelected.$error) return;

      this.sendUpdatedAddress(this.orderSelected.buyerInfo);
    },
    sendUpdatedAddress(value) {
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .post(
          this.$baseUrl +
            "/api/transaction/" +
            this.orderSelected.id +
            "/buyerinfo",
          value
        )
        .then(response => {
          if (response.data.result === 1) {
            if (this.orderSelected.selfPickingLocation)
              this.sendUpdatedSelfPickAddress();
            else {
              this.$store.dispatch("setIsLoading", false);
              this.message = "แก้ไขข้อมูลลูกค้าสำเร็จ";
              this.setShowModalAddress(false);
              this.$refs.modalMessage.show();
            }
          } else {
            this.statusMessage = response.data.message;
            this.$refs.modalMessage.show();
          }
        });
    },
    sendUpdatedSelfPickAddress() {
      this.$axios
        .put(
          `${this.$baseUrl}/api/transaction/${this.orderSelected.id}/SelfPicking`,
          { Location: this.orderSelected.selfPickingLocation }
        )
        .then(response => {
          if (response.data.result === 1) {
            this.$store.dispatch("setIsLoading", false);
            this.message = "แก้ไขข้อมูลลูกค้าสำเร็จ";
            this.setShowModalAddress(false);
            this.$refs.modalMessage.show();
          } else {
            this.statusMessage = response.data.message;
            this.$refs.modalMessage.show();
          }
        });
    },
    setShowModalAddress(value) {
      if (![0, 1].includes(this.orderSelected.statusId)) {
        this.isShowModalAddress = value;

        if (!value) this.getOrderInformationById(this.orderSelected.id);

        this.handleForcrrefresh();
      }
    },
    handleForcrrefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.panel-overflow {
  height: calc(100vh - 55px);
  overflow: auto;
  overflow-x: hidden;
}
</style>
