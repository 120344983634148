var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.transaction.paymentMethodId == 0)?_c('div',{staticClass:"bg-color-secondary rounded shadow-sm p-5 text-color-black"},[_c('span',[_vm._v("-")])]):_c('div',{staticClass:"p-3 bg-color-secondary rounded shadow-sm text-color-black"},[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("ช่องทางการชำระเงิน")]),_c('div',{staticClass:"ml-auto"},[_c('span',[_vm._v(_vm._s(_vm.transaction.paymentName))])])]),(_vm.transaction.paymentMethodId !== 1)?[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("หมายเลขอ้างอิง")]),_c('div',{staticClass:"ml-auto"},[_c('span',[_vm._v(_vm._s(_vm.transaction.paymentReference))])])])]:_vm._e(),[_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("ธนาคารปลายทาง")]),_c('div',{staticClass:"ml-auto"},[(_vm.bannk)?_c('span',[_vm._v(_vm._s(_vm.bannk.bankName))]):_c('span',[_vm._v(" -")])])]),_c('div',{staticClass:"d-flex"},[_c('div',[_vm._v("วันที่/เวลาการชำระเงิน")]),_c('div',{staticClass:"ml-auto"},[(
              _vm.paymentTransferSlip.transferDate &&
                _vm.paymentTransferSlip.transferDate != '0001-01-01T00:00:00'
            )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(_vm.paymentTransferSlip.transferDate),_vm.$formatDate))+" "+_vm._s(_vm.paymentTransferSlip.transferTime?.substring(0, 8)))]):_c('span',[_vm._v("-")])])]),_c('div',{staticClass:"d-flex"},[_vm._m(0),(_vm.paymentTransferSlip.imageList.length == 0)?_c('div',{staticClass:"ml-auto d-flex justify-content-end"},[_vm._v(" - ")]):_vm._e()]),(_vm.paymentTransferSlip.imageList.length > 0)?_c('div',[_c('div',{},[_c('div',{staticClass:"slip-image my-2",style:({
              'background-image':
                'url(' + _vm.paymentTransferSlip.imageList[0].imageUrl + ')'
            })})])]):_vm._e(),(_vm.paymentTransferSlip.imageList.length > 1)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('span',[_c('u',{staticClass:"ml-2 pointer",on:{"click":function($event){return _vm.setShowModalPayment(true)}}},[_vm._v("มีรูปอีก "+_vm._s(_vm.paymentTransferSlip.imageList.length - 1)+" รูป")])])]):_vm._e()]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-2"},[_c('span',[_vm._v("หลักฐานการชำระเงิน")])])
}]

export { render, staticRenderFns }