<template>
  <div>
    <div
      class="p-3 responsivecard shadow-sm bg-color-secondary rounded"
      v-if="buyerInfo.userInfo.firstname || buyerInfo.userInfo.socialName"
    >
      <div class="d-flex">
        <div>
          <label class="panel-image m-0">
            <div
              v-if="buyerInfo.userInfo.profilePictureUrl"
              class="profile-img"
              v-bind:style="{
                backgroundImage:
                  'url(' + buyerInfo.userInfo.profilePictureUrl + ')'
              }"
            ></div>
            <div
              v-else
              class="profile-img"
              v-bind:style="{
                backgroundImage: 'url(' + profile + ')'
              }"
            ></div>
          </label>
        </div>
        <div class="ml-3 d-flex justify-content-between w-100">
          <div class="pl-0 pr-0">
            <div v-if="buyerInfo.userInfo.socialName">
              <div class="font-weight-bold ">
                <span>{{ buyerInfo.userInfo.socialName }}</span>
              </div>

              <div class="breakword">
                {{ buyerInfo.userInfo.firstname }}
                {{ buyerInfo.userInfo.lastname }}
              </div>
              <div class="breakword">
                {{ buyerInfo.userInfo.telephone }}
              </div>
            </div>
            <div v-else>
              <div class="breakword">
                {{ buyerInfo.userInfo.firstname }}
                {{ buyerInfo.userInfo.lastname }}
              </div>
              <div class="breakword">
                {{ buyerInfo.userInfo.telephone }}
              </div>
            </div>
          </div>
          <div class="pl-0 pr-0 text-end ">
            <div class="ml-auto">
              <b-badge
                pill
                variant="primary"
                class="px-3 w-auto py-1 f-size-14"
                :style="styleCustom"
                >{{ orderStatusName }}</b-badge
              >
            </div>
            <div
              class="mt-5 ml-auto mr-2"
              v-if="orderStatusId.statusId == 2 || orderStatusId.statusId == 3"
            >
              <u
                :class="[
                  orderStatusId == 0 || orderStatusId == 1
                    ? 'text-secondary  disabled'
                    : 'pointer'
                ]"
                @click="$emit('setShowModalAddress', true)"
                >ดูรายละเอียดเพิ่ม</u
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 mt-2 shadow-sm bg-color-secondary rounded" v-else>
      <div>-</div>
    </div>

    <div>
      <div>
        <div class="p-3 mt-2 shadow-sm bg-color-secondary rounded">
          <div>
            <template v-if="orderShippingTypeId !== 7">
              <div class="font-weight-bold">
                <font-awesome-icon
                  :icon="['fas', 'map-marker-alt']"
                  class="text-c-red mr-1"
                />ที่อยู่ในการจัดส่ง
              </div>
              <template v-if="buyerInfo.shippingAddress.zipcode">
                <div>
                  {{ buyerInfo.userInfo.firstname }}
                  {{ buyerInfo.userInfo.lastname }}
                </div>
                <div>{{ buyerInfo.shippingAddress.address }}</div>
                <div>
                  {{
                    buyerInfo.shippingAddress.road
                      ? `ถนน ${buyerInfo.shippingAddress.road} `
                      : ``
                  }}

                  {{
                    buyerInfo.shippingAddress.subdistrict
                      ? `แขวง/ตำบล ${buyerInfo.shippingAddress.subdistrict} `
                      : ``
                  }}
                  {{
                    buyerInfo.shippingAddress.district
                      ? `เขต/อำเภอ ${buyerInfo.shippingAddress.district}`
                      : ``
                  }}
                </div>
                <div>
                  {{ buyerInfo.shippingAddress.province }}
                  {{ buyerInfo.shippingAddress.zipcode }}
                </div>
                <div>หมายเลขโทรศัพท์ {{ buyerInfo.userInfo.telephone }}</div>
              </template>
              <template v-else>
                <div class="p-3 m-auto">
                  <span>-</span>
                </div>
              </template>
            </template>
            <template v-else>
              <div>
                <div class="font-weight-bold">
                  ที่อยู่ในการจัดส่ง (รับสินค้าด้วยตัวเอง)
                </div>
                <div>
                  {{
                    orderShippingLocation || buyerInfo.shippingAddress.address
                  }}
                </div>
              </div>
            </template>
          </div>
          <div v-if="buyerInfo.sameAddress">
            <p class="mb-0 mt-1">
              <font-awesome-icon icon="check-circle" />
              <span class="ml-1">ใช้ที่อยู่จัดส่งเป็นที่อยู่ใบเสร็จ</span>
            </p>
            <div>
              เลขประจำตัวผู้เสียภาษี
              <span>{{ buyerInfo.billingAddress.tax || "-" }}</span>
            </div>
          </div>
          <div v-else class="mt-3">
            <div class="d-flex justify-content-between">
              <div class="font-weight-bold">ที่อยู่ที่ใช้ในการออกใบเสร็จ</div>
            </div>

            <div
              v-if="buyerInfo.isRequestTax || !buyerInfo.userInfo.sameAddress"
            >
              <div>
                {{ nameTitle ? nameTitle.name : null }}
                {{ buyerInfo.billingAddress.firstname }}
                {{ buyerInfo.billingAddress.lastname }}
              </div>
              <div>{{ buyerInfo.userInfo.telephone }}</div>
              <div>
                {{ buyerInfo.billingAddress.address }}
              </div>
              <div>
                {{
                  buyerInfo.billingAddress.road
                    ? `ถนน ${buyerInfo.billingAddress.road} `
                    : ``
                }}

                {{
                  buyerInfo.billingAddress.subdistrict
                    ? `แขวง/ตำบล ${buyerInfo.billingAddress.subdistrict} `
                    : ``
                }}
                {{
                  buyerInfo.billingAddress.district
                    ? `เขต/อำเภอ ${buyerInfo.billingAddress.district}`
                    : ``
                }}
              </div>
              <div>
                {{ buyerInfo.billingAddress.province }}
                {{ buyerInfo.billingAddress.zipcode }}
              </div>
              <div>
                เลขประจำตัวผู้เสียภาษี
                <span v-if="buyerInfo.billingAddress.tax">{{
                  buyerInfo.billingAddress.tax
                }}</span>
                <span v-else>-</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import profile from "@/assets/images/userMenu-profile.svg";
export default {
  props: {
    buyerInfo: {
      required: true,
      type: Object
    },
    socialInfo: {
      required: true,
      type: Object
    },
    orderStatusId: {
      required: true,
      type: Number
    },
    orderStatusName: {
      required: true,
      type: String
    },
    orderShippingTypeId: {
      required: true,
      type: Number
    },
    orderShippingLocation: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      nameTitleArray: [],
      profile: profile
    };
  },
  computed: {
    nameTitle() {
      return this.nameTitleArray.find(title => {
        if (title.id === this.buyerInfo.shippingAddress.nameTitleId) {
          return title.name;
        }
      });
    },
    styleCustom() {
      if (this.orderStatusId == 5) {
        return {
          backgroundColor: "#d4edda",
          color: "#155724"
        };
      } else if ([6, 7, 8, 12].includes(this.orderStatusId)) {
        return {
          backgroundColor: "#f8d7da",
          color: "#721c24"
        };
      } else if ([1].includes(this.orderStatusId)) {
        return {
          backgroundColor: "#e2e3e5",
          color: "#383d41"
        };
      } else if ([4, 14, 15].includes(this.orderStatusId)) {
        return {
          backgroundColor: "rgb(113 96 196 / 27%)",
          color: "#7160C4"
        };
      } else {
        return {
          backgroundColor: "#fff3cd",
          color: "#856404"
        };
      }
    }
  },
  created() {
    this.getNameTitle();
  },
  methods: {
    async getNameTitle() {
      try {
        const response = await this.$axios.post(
          `${this.$baseUrl}/api/filter/NameTitle`
        );
        this.nameTitleArray = response.data.detail;
      } catch (error) {
        console.error("nameTitle fetch fail");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}

.panel-image {
  width: 70px;
  height: 70px;
  position: relative;
  cursor: pointer;
}
.f-size-14 {
  font-size: 14px;
}
.text-end {
  text-align: end;
}
.breakword {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.text-c-red {
  color: #832abf;
}
@media (max-width: 350px) {
  .responsivecard {
    overflow: auto;
  }
}
</style>
