<template>
  <div>
    <div
      class="rounded shadow-sm p-3 bg-color-secondary my-1 d-flex"
      v-if="freeproduct.selected"
    >
      <div class="my-auto">
        <div
          class="product-image my-2"
          v-bind:style="{
            'background-image': 'url(' + freeproduct.imageUrl + ')'
          }"
        ></div>
      </div>
      <div class="ml-2 w-100">
        <div class="d-flex">
          <div class="line-clamp">{{ freeproduct.name }}</div>
          <div class="ml-auto">{{ freeproduct.quantity }}</div>
        </div>

        <div class="f-size-14 text-secondary">
          {{ freeproduct.barcode }} ( {{ freeproduct.sku || "-" }} )
        </div>

        <div class="d-flex mt-auto">
          <div class="f-size-14 text-secondary">
            {{ freeproduct.price | numeral("0,0.00") }} x
            {{ freeproduct.quantity }}
          </div>
          <div class="ml-auto f-size-14 text-secondary">
            {{ freeproduct.price | numeral("0,0.00") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    freeproduct: Object
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.product-image {
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.line-clamp {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
